<!-- @migration-task Error while migrating Svelte code: Export 'classNames' is not defined -->
<script lang="ts" context="module">
	export type LinkOpenTarget = "_self" | "_blank";
	export type LinkRelationship = "" | "external noopener noreferrer";
</script>

<script lang="ts">
	import { HttpRegex } from "$lib/utils/externalLink";

	export let enableUnderline: boolean = true;

	const baseStyles = [
		enableUnderline ? "underline" : "",
		"cursor-pointer",
		"font-medium",
		"prose-a:font-medium"
	];
	let additionalClasses = "";
	export { additionalClasses as class };

	export let href: string | undefined;
	const isExternalLink: boolean = !!href && HttpRegex.test(href);

	export let target: LinkOpenTarget = isExternalLink ? "_blank" : "_self";
	export let rel: LinkRelationship = isExternalLink ? "external noopener noreferrer" : "";
</script>

<a {href} {target} {rel} class={baseStyles.join(" ") + " " + additionalClasses}>
	<slot></slot>
</a>
